<template>
  <div v-if="$route.meta.showHeader">

    <v-app-bar id="app-header" :class="$vuetify.breakpoint.mdAndUp? 'class-appbar' : 'class-appbar-mobile pr-0'" clipped-right clipped-left app elevation="1">

      <v-app-bar-nav-icon v-if="$vuetify.breakpoint.mdAndUp" class="pa-3 class-logo ml-10">
        <v-img src="../assets/Jata_Logo.png" height="50" contain @click="goToPage('/Dashboard')">
        </v-img>
      </v-app-bar-nav-icon>

      <v-app-bar-nav-icon v-else class="pa-3 class-logo ml-2">
        <v-img src="../assets/logo-header.png" height="40" contain @click="goToPage('/Dashboard')">
        </v-img>
      </v-app-bar-nav-icon>

      <v-toolbar-title v-if="$vuetify.breakpoint.mdAndUp" class="class-title ml-16">
        WATER QUALITY MONITORING SYSTEM
      </v-toolbar-title>

      <!-- <v-toolbar-title v-else class="class-title-mobile ml-8">
        <h3>EDMP</h3>
      </v-toolbar-title> -->

      <v-spacer></v-spacer>

      <div class="class-icongroup pa-2">

        <!-- //INFORMATION -->

        <v-menu origin="center center" transition="scale-transition" offset-y>

          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn color="grey" icon rounded v-bind="attrs" v-on="{ ...tooltip, ...menu }"
                  @click="overlay = !overlay">
                  <v-icon color="white" role="img">mdi-information-slab-circle-outline</v-icon>
                </v-btn>
              </template>
              <span>Information</span>
            </v-tooltip>
          </template>
        </v-menu>

        <!-- //USER MANUAL -->
        <v-menu origin="center center" transition="scale-transition" offset-y>

          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn v-if="$vuetify.breakpoint.lgAndUp" color="grey" icon rounded v-bind="attrs" v-on="{ ...tooltip, ...menu }"
                  @click="goToPage('/Mapviewer')">
                  <v-icon color="white" role="img">mdi-map-outline</v-icon>
                </v-btn>
                <v-btn v-else color="grey" icon rounded v-bind="attrs" v-on="{ ...tooltip, ...menu }"
                  @click="goToPage('/Dashboard')">
                  <v-icon color="white" role="img">mdi-map-outline</v-icon>
                </v-btn>
              </template>
              <span>Full Map</span>
            </v-tooltip>
          </template>
        </v-menu>

        <!-- //MOBILE-->
        <v-menu origin="center center" transition="scale-transition" offset-y v-if="$vuetify.breakpoint.smAndDown">
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn color="grey" icon rounded v-bind="attrs" v-on="{ ...tooltip, ...menu }"
                  @click="mobileNavBar = !mobileNavBar">
                  <v-icon color="white" role="img">mdi-menu</v-icon>
                </v-btn>
              </template>
              <span>Menu</span>
            </v-tooltip>
          </template>
        </v-menu>

        <!-- //ALERT BUTTON -->
        <v-menu origin="center" transition="scale-transition" offset-y class="mt-16" v-if="$vuetify.breakpoint.mdAndUp && this.$store.getters.user.idUserAccess !== 3">
          <template v-slot:activator="{ on, attrs }">
            <v-tooltip  bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn color="grey" icon rounded v-bind="attrs" v-on="{ ...tooltip, ...on }">
                  <v-icon  color="white" role="img">mdi-bell-alert</v-icon>
                </v-btn>
              </template>
              <span>Alert</span>
            </v-tooltip>
          </template>
          <v-list class="list-inChild" flat>
            <v-subheader class="subHeaderList">Alert</v-subheader>
            <v-list-item-group color="primary" class="bbb">
              <v-list-item v-for="(item, index) in alertItems" :key="index" class="ccc" :to="item.url">
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>

        <!-- //REPORT BUTTON -->
        <v-menu origin="center center" transition="scale-transition" offset-y v-if="$vuetify.breakpoint.mdAndUp" @click="clickReport">
          <template v-slot:activator="{ on, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn @click="clickReport" color="grey" icon rounded v-bind="attrs" v-on="{ ...tooltip, ...on }">
                  <v-icon color="white" role="img">mdi-file-document</v-icon>
                </v-btn>
              </template>
              <span>Report</span>
            </v-tooltip>
          </template>

          <v-list class="list-inChild">
            <v-subheader class="subHeaderList">Water Quality Report</v-subheader>
            <v-list-item-group color="primary">
              <v-list-item v-for="(item, index) in wqReportItems" :key="index" :to="item.url">
                <v-list-item-title disabled v-text="item.title"></v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <v-list class="list-inChild" v-if="this.$store.getters.user.idUserAccess !== 3 && this.$store.getters.user.idUserAccess !== 2 && role != 5">
            <v-subheader class="subHeaderList">Operational</v-subheader>
            <v-list-item-group color="primary">
              <v-list-item v-for="(item, index) in operationReportItems" :key="index" :to="item.url">
                <v-list-item-title v-if="item.title != 'QAQC 2sec Level'" v-text="item.title"></v-list-item-title>
                <v-list-item-title v-if="item.title == 'QAQC 2sec Level'">
                  {{ item.title.split("sec")[0] }}<sup>nd</sup> <span class="ml-0">{{ item.title.split("sec")[1]
                    }}</span>
                </v-list-item-title>

              </v-list-item>
            </v-list-item-group>
          </v-list>

          <v-list class="list-inChild" v-if="this.$store.getters.user.idUserAccess == 2 && role != 5">
            <v-subheader class="subHeaderList">Operational</v-subheader>
            <v-list-item-group color="primary">
              <v-list-item v-for="(item, index) in operationRoleReportItems" :key="index" :to="item.url">
                <v-list-item-title v-if="item.title != 'QAQC 2sec Level'" v-text="item.title"></v-list-item-title>
                <v-list-item-title v-if="item.title == 'QAQC 2sec Level'">
                  {{ item.title.split("sec")[0] }}<sup>nd</sup> <span class="ml-0">{{ item.title.split("sec")[1]
                    }}</span>
                </v-list-item-title>

              </v-list-item>
            </v-list-item-group>
          </v-list>

          <v-list class="list-inChild" v-if="this.$store.getters.user.idUserAccess !== 3 && this.$store.getters.user.idUserAccess !== 2 && role != 5">
            <v-subheader class="subHeaderList">CMS Reporting</v-subheader>
            <v-list-item-group color="primary">
              <v-list-item v-for="(item, index) in cmsReportItems" :key="index" :to="item.url">
                <v-list-item-title disabled v-text="item.title"></v-list-item-title>

              </v-list-item>
            </v-list-item-group>
          </v-list>

        </v-menu>

        <!-- Button System Admin -->
        <v-menu origin="center center" transition="scale-transition" offset-y v-if="$vuetify.breakpoint.mdAndUp">
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>

              <template v-slot:activator="{ on: tooltip }">
                <v-btn color="grey" icon rounded v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                  <v-icon color="white" role="img">mdi-tune</v-icon>
                </v-btn>
              </template>
              <span>Settings</span>
            </v-tooltip>
          </template>
          <v-list class="list-inChild">
            <v-subheader class="subHeaderList">Settings</v-subheader>
            <v-list-item-group color="primary">
              <v-list-item to='/userProfile/userProfile'>
                <v-list-item-title>User Profile</v-list-item-title>
              </v-list-item>

              <v-list-item v-show="foradmin" to="/systemAdmin/userManagement/userList">
                <v-list-item-title>System Admin</v-list-item-title>
              </v-list-item>

              <v-list-item @click="openPdf()">
                <v-list-item-title>User Manual</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>

        <!-- Button Logout -->
        <v-menu origin="center center" transition="scale-transition" offset-y>

          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn color="grey" icon rounded v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                  <v-icon color="white" role="img" @click="signOut">mdi-logout</v-icon>
                </v-btn>
              </template>
              <span>Log Out</span>
            </v-tooltip>
          </template>

        </v-menu>
      </div>

      <!-- </v-app-bar-nav-icon> -->

    </v-app-bar>

    <v-dialog
    v-model="overlay"
    width="750"
    scrollable
    persistent
    :fullscreen="$vuetify.breakpoint.mdAndUp? false : true"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          
          <span>
            <v-icon large class="mr-2 primary--text">mdi-information</v-icon>
            Information
          </span>

          <v-spacer></v-spacer>

          <v-icon style="cursor:pointer;" class="mr-2 primary--text" @click="overlay = false">mdi-close</v-icon>

        </v-card-title>

        <v-card-text :class="{'pa-0':!$vuetify.breakpoint.mdAndUp}" style="height: 500px;">
          
          <v-tabs show-arrows="mobile" v-model="tabs">
            <v-tab v-for="item in information" :key="item.key">
              {{ item.name }}
            </v-tab>
            <v-tabs-items v-model="tabs">

              <v-divider class="mt-3"></v-divider>
              <v-tab-item v-for="item in information" :key="item.key">
                <v-card flat>
                  <v-card-text style="overflow: auto; height: 400px;">

                    <v-row class="" v-for="i in item.sub_item" :key="i.key">

                      <v-col class="pa-3 text-subtitle-1 font-weight-bold align-center pa-5" style="border:1px solid #c1c1c1;border:border-radius:2px;">
                        <span>{{ i.title }}</span>
                      </v-col>

                      <!-- <v-divider></v-divider> -->

                      <v-col class="pa-3"  style="border:1px solid #c1c1c1;border:border-radius:2px;">
                        <span v-html="i.text"></span>
                      </v-col>
                      
                    </v-row>

                  </v-card-text>
                </v-card>
              </v-tab-item>

            </v-tabs-items>
          </v-tabs>

        </v-card-text>

        <!-- <v-divider></v-divider> -->

        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            I accept
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
    
    <!-- <div v-show="overlay == true" class="overlay-class">

      <template>

        
      </template>


    </div> -->

    <!-- //MOBILE BOTTOM NAV-->
    <div v-if="this.$store.getters.user.idUserAccess == 1">
      <v-bottom-sheet v-model=" mobileNavBar">
        <v-sheet class="bottom-sheet-mobile-background">
          <v-expansion-panels>
            <v-expansion-panel v-for="(item,i) in mobileitems" :key="i">
              <v-expansion-panel-header class="d-flex expansion-header" style="background-color: #004892;">
                <v-card-text class="white--text pa-0 ma-0 font-weight-bold">
                  <v-icon class="mr-2 white--text">{{ item.icon }}</v-icon> {{ item.title }}
                </v-card-text>
              </v-expansion-panel-header>

              <v-expansion-panel-content>

                <v-list-item dense link v-for="(list, j) in item.subheader" :key="j" :to="list.url"
                  @click="mobileNavBar = false">
                  <v-card-subtitle class="font-weight-bold">
                    <v-icon class="mr-4">{{list.icon}}</v-icon> {{list.title}}
                  </v-card-subtitle>

                </v-list-item>

              </v-expansion-panel-content>

            </v-expansion-panel>
          </v-expansion-panels>
        </v-sheet>
      </v-bottom-sheet>
    </div>

    <div v-if="this.$store.getters.user.idUserAccess == 4">
      <v-bottom-sheet v-model=" mobileNavBar">
        <v-sheet class="bottom-sheet-mobile-background">
          <v-expansion-panels>
            <v-expansion-panel v-for="(item,i) in mobileitemsQaqc" :key="i">
              <v-expansion-panel-header class="d-flex expansion-header" style="background-color: #004892;">
                <v-card-text class="white--text pa-0 ma-0 font-weight-bold">
                  <v-icon class="mr-2 white--text">{{ item.icon }}</v-icon> {{ item.title }}
                </v-card-text>
              </v-expansion-panel-header>

              <v-expansion-panel-content>

                <v-list-item dense link v-for="(list, j) in item.subheader" :key="j" :to="list.url"
                  @click="mobileNavBar = false">
                  <v-card-subtitle class="font-weight-bold">
                    <v-icon class="mr-4">{{list.icon}}</v-icon> {{list.title}}
                  </v-card-subtitle>

                </v-list-item>

              </v-expansion-panel-content>

            </v-expansion-panel>
          </v-expansion-panels>
        </v-sheet>
      </v-bottom-sheet>
    </div>

    <div v-if="this.$store.getters.user.idUserAccess == 3">
      <v-bottom-sheet v-model=" mobileNavBar">
        <v-sheet class="bottom-sheet-mobile-background">
          <v-expansion-panels>
            <v-expansion-panel v-for="(item,i) in mobileitemsGeneral" :key="i">
              <v-expansion-panel-header class="d-flex expansion-header" style="background-color: #004892;">
                <v-card-text class="white--text pa-0 ma-0 font-weight-bold">
                  <v-icon class="mr-2 white--text">{{ item.icon }}</v-icon> {{ item.title }}
                </v-card-text>
              </v-expansion-panel-header>

              <v-expansion-panel-content>

                <v-list-item dense link v-for="(list, j) in item.subheader" :key="j" :to="list.url"
                  @click="mobileNavBar = false">
                  <v-card-subtitle class="font-weight-bold">
                    <v-icon class="mr-4">{{list.icon}}</v-icon> {{list.title}}
                  </v-card-subtitle>

                </v-list-item>

              </v-expansion-panel-content>

            </v-expansion-panel>
          </v-expansion-panels>
        </v-sheet>
      </v-bottom-sheet>
    </div>

    <div v-if="this.$store.getters.user.idUserAccess == 2">
      <v-bottom-sheet v-model=" mobileNavBar">
        <v-sheet class="bottom-sheet-mobile-background">
          <v-expansion-panels>
            <v-expansion-panel v-for="(item,i) in mobileitemsOperation" :key="i">
              <v-expansion-panel-header class="d-flex expansion-header" style="background-color: #004892;">
                <v-card-text class="white--text pa-0 ma-0 font-weight-bold">
                  <v-icon class="mr-2 white--text">{{ item.icon }}</v-icon> {{ item.title }}
                </v-card-text>
              </v-expansion-panel-header>

              <v-expansion-panel-content>

                <v-list-item dense link v-for="(list, j) in item.subheader" :key="j" :to="list.url"
                  @click="mobileNavBar = false">
                  <v-card-subtitle class="font-weight-bold">
                    <v-icon class="mr-4">{{list.icon}}</v-icon> {{list.title}}
                  </v-card-subtitle>

                </v-list-item>

              </v-expansion-panel-content>

            </v-expansion-panel>
          </v-expansion-panels>
        </v-sheet>
      </v-bottom-sheet>
    </div>


  </div>
</template>

<script>
  import { bus } from '@/main';

  export default {

    data: () => ({

      tabs: "",
      overlay: false,
      foradmin: false,
      forgen: true,
      mobileNavBar: false,
      drawer: false,
      group: null,
      role: 0,
      alertItems: [
        // { title: 'Exceedance', url: "/Alert/Exceedance" },
        { title: 'Data Transmission Status', url: "/Alert/Transmission" },
        { title: 'Exceedance Alert Record', url: "/Alert/ExceedanceByParameter" },
      ],
      wqReportItems: [
        { title: 'Daily Report', url: "/Report/DailyReport" },
        { title: 'Monthly Report', url: "/Report/MonthlyReport" },
        { title: 'Yearly Report', url: "/Report/YearlyReport" },
        { title: 'Query Options', url: "/Report/QueryOptions" },
        { title: 'Raw Data', url: "/Report/RawData" }
      ],
      operationReportItems: [
        { title: 'Maintenance', url: "/Operational/Maintenance" },
        { title: 'Calibration', url: "/Operational/Calibration" },
        { title: 'QAQC 2sec Level', url: "/Operational/QAQCSecLevel" },
        { title: 'Validation', url: "/Operational/Validation" }
      ],
      operationRoleReportItems: [
        { title: 'Maintenance', url: "/Operational/Maintenance" },
        { title: 'Calibration', url: "/Operational/Calibration" }
      ],
      cmsReportItems: [
        { title: 'Reporting', url: "/CMSReporting/Reporting" },
      ],
      userItems: [
        { title: 'User Profile', url: "/userProfile/userProfile" },
        { title: 'System Admin', url: "/systemAdmin/userManagement/userList" },
      ],

      mobileitems: [
        {
          title: 'Water Quality Report',
          icon: "mdi-file-document",
          subheader: [
            { title: 'Daily Report', url: '/Report/DailyReport', icon: 'mdi-file-document-outline' },
            { title: 'Monthly Report', url: '/Report/MonthlyReport', icon: 'mdi-file-document-outline' },
            { title: 'Yearly Report', url: '/Report/YearlyReport', icon: 'mdi-file-document-outline' },
            { title: 'Query Options', url: '/Report/QueryOptions', icon: 'mdi-database-settings-outline' },
            { title: 'Raw Data', url: '/Report/RawData', icon: 'mdi-file-sign' }
          ]
        },
        {
          title: 'Operational',
          icon: "mdi-file-cog",
          subheader: [
            { title: 'Maintenance', url: '/Operational/Maintenance', icon: 'mdi-progress-wrench' },
            { title: 'Calibration', url: '/Operational/Calibration', icon: 'mdi-tune' },
            { title: 'QAQC 2nd Level', url: '/Operational/QAQCSecLevel', icon: 'mdi-nfc-search-variant' },
            { title: 'Validation', url: '/Operational/Validation', icon: 'mdi-file-sign' }
          ]
        },
        {
          title: 'CMS Reporting',
          icon: "mdi-file-sign",
          subheader: [
            { title: 'Reporting', url: '/CMSReporting/Reporting', icon: 'mdi-file-document-outline' }
          ]
        },
        {
          title: 'Alert',
          icon: "mdi-bell-alert",
          subheader: [
            // { title: 'Exceedance', url: '/Alert/Exceedance', icon: 'mdi-water-percent-alert' },
            { title: 'Data Transmission Status', url: '/Alert/Transmission', icon: 'mdi-list-status' },
            { title: 'Exceedance by Parameter', url: '/Alert/ExceedanceByParameter', icon: 'mdi-beaker-alert-outline' }
          ]
        },
        {
          title: 'Settings',
          icon: "mdi-tune",
          subheader: [
            { title: 'User Profile', url: '/userProfile/userProfile', icon: 'mdi-badge-account-horizontal-outline' },
            { title: 'System Admin', url: '/systemAdmin/userManagement/userList', icon: 'mdi-account-tie' },
            { title: 'User Manual', url: '/systemAdmin/userManagement/userList', icon: 'mdi-book-open-page-variant' }
          ]
        }

      ],

      mobileitemsQaqc: [
        {
          title: 'Water Quality Report',
          icon: "mdi-file-document",
          subheader: [
            { title: 'Daily Report', url: '/Report/DailyReport', icon: 'mdi-file-document-outline' },
            { title: 'Monthly Report', url: '/Report/MonthlyReport', icon: 'mdi-file-document-outline' },
            { title: 'Yearly Report', url: '/Report/YearlyReport', icon: 'mdi-file-document-outline' },
            { title: 'Query Options', url: '/Report/QueryOptions', icon: 'mdi-database-settings-outline' },
            { title: 'Raw Data', url: '/Report/RawData', icon: 'mdi-file-sign' }
          ]
        },
        {
          title: 'Operational',
          icon: "mdi-file-cog",
          subheader: [
            { title: 'Maintenance', url: '/Operational/Maintenance', icon: 'mdi-progress-wrench' },
            { title: 'Calibration', url: '/Operational/Calibration', icon: 'mdi-tune' },
            { title: 'QAQC 2nd Level', url: '/Operational/QAQCSecLevel', icon: 'mdi-nfc-search-variant' },
            { title: 'Validation', url: '/Operational/Validation', icon: 'mdi-file-sign' }
          ]
        },
        {
          title: 'CMS Reporting',
          icon: "mdi-file-sign",
          subheader: [
            { title: 'Reporting', url: '/CMSReporting/Reporting', icon: 'mdi-file-document-outline' }
          ]
        },
        {
          title: 'Alert',
          icon: "mdi-bell-alert",
          subheader: [
            { title: 'Exceedance', url: '/Alert/Exceedance', icon: 'mdi-water-percent-alert' },
            { title: 'Data Transmission Status', url: '/Alert/Transmission', icon: 'mdi-list-status' },
            { title: 'Exceedance by Parameter', url: '/Alert/ExceedanceByParameter', icon: 'mdi-beaker-alert-outline' }
          ]
        },
        {
          title: 'Settings',
          icon: "mdi-tune",
          subheader: [
            { title: 'User Profile', url: '/userProfile/userProfile', icon: 'mdi-badge-account-horizontal-outline' },
            { title: 'User Manual', url: '/systemAdmin/userManagement/userList', icon: 'mdi-book-open-page-variant' }
          ]
        }

      ],

      mobileitemsGeneral: [
        {
          title: 'Water Quality Report',
          icon: "mdi-file-document",
          subheader: [
            { title: 'Daily Report', url: '/Report/DailyReport', icon: 'mdi-file-document-outline' },
            { title: 'Monthly Report', url: '/Report/MonthlyReport', icon: 'mdi-file-document-outline' },
            { title: 'Yearly Report', url: '/Report/YearlyReport', icon: 'mdi-file-document-outline' },
            { title: 'Query Options', url: '/Report/QueryOptions', icon: 'mdi-database-settings-outline' },
            { title: 'Raw Data', url: '/Report/RawData', icon: 'mdi-file-sign' }
          ]
        },
        {
          title: 'Settings',
          icon: "mdi-tune",
          subheader: [
            { title: 'User Profile', url: '/userProfile/userProfile', icon: 'mdi-badge-account-horizontal-outline' },
            { title: 'User Manual', url: '/systemAdmin/userManagement/userList', icon: 'mdi-book-open-page-variant' }
          ]
        }
      ],

      mobileitemsOperation: [
        {
          title: 'Water Quality Report',
          icon: "mdi-file-document",
          subheader: [
            { title: 'Daily Report', url: '/Report/DailyReport', icon: 'mdi-file-document-outline' },
            { title: 'Monthly Report', url: '/Report/MonthlyReport', icon: 'mdi-file-document-outline' },
            { title: 'Yearly Report', url: '/Report/YearlyReport', icon: 'mdi-file-document-outline' },
            { title: 'Query Options', url: '/Report/QueryOptions', icon: 'mdi-database-settings-outline' },
            { title: 'Raw Data', url: '/Report/RawData', icon: 'mdi-file-sign' }
          ]
        },
        {
          title: 'Operational',
          icon: "mdi-file-cog",
          subheader: [
            { title: 'Maintenance', url: '/Operational/Maintenance', icon: 'mdi-progress-wrench' },
            { title: 'Calibration', url: '/Operational/Calibration', icon: 'mdi-tune' },
          ]
        },
        {
          title: 'Alert',
          icon: "mdi-bell-alert",
          subheader: [
            { title: 'Exceedance', url: '/Alert/Exceedance', icon: 'mdi-water-percent-alert' },
            { title: 'Data Transmission Status', url: '/Alert/Transmission', icon: 'mdi-list-status' },
            { title: 'Exceedance by Parameter', url: '/Alert/ExceedanceByParameter', icon: 'mdi-beaker-alert-outline' }
          ]
        },
        {
          title: 'Settings',
          icon: "mdi-tune",
          subheader: [
            { title: 'User Profile', url: '/userProfile/userProfile', icon: 'mdi-badge-account-horizontal-outline' },
            { title: 'User Manual', url: '/systemAdmin/userManagement/userList', icon: 'mdi-book-open-page-variant' }
          ]
        }
      ],

      information: [
        {
          name: "About Us",
          sub_item: [
            { title: 'Who Are We', text: "Badan Kawalselia Air Negeri Johor (BAKAJ) manage and regulate raw water in Johor" },
            { title: 'The Objective of Water Quality Telemetric Installation', text: "To monitor the quality of current river water continuously and provide an early warning if contamination occurs, directly enabling relevant agencies to take control measures quickly" }
          ]
        },
        {
          name: "Parameter Information",
          sub_item: [
            {
              title: 'Dissolved Oxygen',
              text: "Dissolved oxygen (DO) refers to the amount of oxygen in the water. Bodies of water receive oxygen from the atmosphere and from aquatic plants through the process of photosynthesis. Flowing water has relatively higher oxygen content than stagnant water such as ponds or lakes. Dissolved oxygen is not only important for aquatic life but is also used in the decomposition of excess organic matter. DO levels below 3 mg/L are a concern while below 1 mg/L is considered hypoxia or dead water"
            },
            {
              title: 'pH',
              text: "pH is a measure of the acidity and alkalinity of a solution. The pH scale ranges from 0 to 14. Water with a pH less than 7 is considered acidic, while a pH greater than 7 is neutral or alkaline. Acidic water can be attributed to various causes. In terms of natural factors, soil properties affect the pH value of raw water. Peat water mainly has low pH due to the nature of peat soil which has a high content of organic matter and soil porosity, thus increasing the rate of diffusion of organic matter into the water and further causing the acidity of the water to increase (increase of carbonate ions). The high acidity of water then causes the dissolved heavy metals such as aluminium and iron to increase because they exist in the form of free ions. Apart from natural factors, the pH value of raw water is contributed by the release of industrial effluents, the use of fertilizers in agricultural activities and mineral ore mining activities."
            },
            {
              title: 'Dissolved Oxygen',
              text: "Dissolved oxygen (DO) refers to the amount of oxygen in the water. Bodies of water receive oxygen from the atmosphere and from aquatic plants through the process of photosynthesis. Flowing water has relatively higher oxygen content than stagnant water such as ponds or lakes. Dissolved oxygen is not only important for aquatic life but is also used in the decomposition of excess organic matter. DO levels below 3 mg/L are a concern while below 1 mg/L is considered hypoxia or dead water"
            },
            {
              title: 'Ammonia',
              text: "Ammonia nitrogen pollution mainly contributed to WTP shutdown. High ammonia levels cause the increasing of chlorine / disinfectant agent dosing during water treatment. Naturally, ammonia produced through the decay of organic matter. The other main sources of ammonia pollution are from the use of fertilizers in agricultural activities, leachate from landfill sites, sewage that is not treated efficiently, sewage from animal husbandry activities, effluent from industrial activities, effluent from palm oil processing premises etc"
            },
            {
              title: 'Turbidity',
              text: "Turbidity can be defined as a measure of water clarity. The turbidity value is affected by the presence of solids and colloids in the water such as sand, clay, organic matter such as algae, plankton, and other microscopic organisms. A high turbidity value indirectly indicates the presence of high suspended solids in the water body. High turbidity of raw water causes the use of flocculation agents, aluminium sulphate in the water treatment process to increase"
            },
            {
              title: 'Salinity',
              text: "Salinity is simply a measure of the amount of salts dissolved in water. The fresh water from rivers has a salinity of 0.5 ppt or less. Within the estuary, salinity levels are referred to as oligohaline (0.5-5.0 ppt), mesohaline (5.0-18.0 ppt), or polyhaline (18.0¬ 30.0 ppt). Near the connection with the open sea, estuarine waters may be euhaline, where salinity levels are the same as the ocean at more than 30.0 ppt"
            },
            {
              title: 'Conductivity',
              text: "Conductivity is a measurement of water's ability to conduct electricity. Conductivity is not the pollutant itself but serves as an indication of the presence of the pollutant. The conductivity of water is affected by the presence of dissolved substances in the water, including salts and heavy metals. Some of these substances are dangerous for aquatic life and humans, especially at high concentrations. Saline water (seawater) has high conductivity due to high concentration of salt (chloride) in the water"
            },
            {
              title: 'Total Dissolved Solids',
              text: "Total dissolved solids (TDS) is anything that dissolved in water, such as metals, minerals, salts, and ions. It may come natural cycles such as surface runoff, mineral erosion, and the disposal of industrial chemical waste into the water. Saline water (seawater) contain high TDS due to the presence of chloride salt in the water"
            },
            {
              title: 'Total Suspended Solids',
              text: "TSS include anything that drifts or floats in water, from sediment, silt, and sand to plankton and algae. The amount of suspended solids is an important factor in determining water turbidity. The higher the content of suspended solids, the higher the turbidity of the water"
            },
            {
              title: 'Nitrate',
              text: "Nitrates are a form of nitrogen, which is found in several different forms in terrestrial and aquatic ecosystems. These forms of nitrogen include ammonia (NH3), nitrates (NO3), and nitrites (NO2). Nitrates are essential plant nutrients, but in excess amounts they can cause significant water quality problems. Together with phosphorus, nitrates in excess amounts can accelerate eutrophication, causing dramatic increases in aquatic plant growth and changes in the types of plants and animals that live in the stream. This, in turn, affects dissolved oxygen, temperature, and other indicators. Excess nitrates can cause hypoxia (low levels of dissolved oxygen) and can become toxic to warm-blooded animals at higher concentrations (10 mg/L) or higher) under certain conditions. The natural level of ammonia or nitrate in surface water is typically low (less than 1 mg/L); in the effluent of wastewater treatment plants, it can range up to 30 mg/L"
            },
            {
              title: 'Polycyclic Aromatic Hydrocarbon (PAH)',
              text: "Polycyclic aromatic hydrocarbons (PAHs), a chemical group that has two or more condensed aromatic rings, are ubiquitous compounds in air, water, and soil and are categorized as general environmentally harmful pollutants. PAHs are especially widely detected in the aquatic environment, including water, sediment, fish, benthic invertebrate. It also represents pollution from hydrocarbons pollutant such as oil, diesel and solvents"
            },
          ]
        },
        {
          name: "Reference Standard",
          sub_item: [
            {
              title: "Recommended Raw Water Quality Criteria",
              text: "Recommended Raw Water Quality Criteria	Recommended Raw Water Quality Criteria (RRWQC) is a set out the maximum acceptance levels of characteristics and constituents in raw water, which if exceeded will require special treatment. For raw water with constituents whose level of concentration is below the recommended criteria, only conventional water treatment is necessary. This criterion was established in 2000 by the Ministry of Health. For more info click  <a href='https://tinyurl.com/RAW-Water-Standard' target='_blank'>here</a> "
            },
            {
              title: "National Water Quality Standard",
              text: "Department of Environment Malaysia has established National Water Quality Standard to assess surface water quality status. The Water Quality Index introduced by DOE has been practiced in Malaysia for about 25 years and serves as the basis for environmental water quality assessment. For more info click  <a href='https://www.doe.gov.my/wp-content/uploads/2021/11/Standard-Kualiti-Air-Kebangsaan.pdf' target='_blank'>here</a> "
            },
          ]
        }
      ],



      logOut: {
        title: 'Log Out'
      },
    }),

    methods: {

      signOut() {
        this.$store.dispatch('signOut')
      },

      openPdf() {
        // window.open("file/manual.pdf","_blank");
        window.open("Sample.pdf", "_blank");
      },

      openMobileNav() {
        bus.$emit('openNavBar', 'mobile');
      },

      clickReport() {
        this.role = this.$store.getters.user.role;
      }
    },

    created() {
      bus.$on('admincanuse', (data) => {
        if (data === 1) {
          this.foradmin = true;
        }

        else {
          this.foradmin = false;
        }
      });
    },

    mounted() {
      this.role = this.$store.getters.user.role;
      let thisuser = this.$store.getters.user.idUserAccess;

      

      if (thisuser === 1) {
        this.foradmin = true;
      }

      else {
        this.foradmin = false;
      }

      if (thisuser === 3) {
        this.forgen = false;

      }

      else {
        this.forgen = true;
      }

      console.log("general user", this.$store.getters.user.idUserAccess)


    }

  };
</script>

<style lang="scss">
  @import '~scss/main';

  .class-logo {
    position: absolute;
    left: 30px;
  }

  .class-title {
    position: absolute;
    left: 100px;
    color: white;
    line-height: normal !important;
  }

  .class-title-mobile {
    position: absolute;
    left: 80px;
    color: white;
  }

  .class-appbar {
    // background-image: linear-gradient(to right, #343333, #5a5a5a) !important;
    // background-image: linear-gradient(to left,#02B3C3, #0081BA) !important; 
    background-image: url("~@/assets/header2.jpg") !important;
    background-size: cover !important;
    background-position: 50% 60%;
    overflow-y: hidden;
    height: 100%;
    position: relative;
  }

  .class-appbar-mobile {
    background: #032d57 !important;
    background-size: cover !important;
    background-position: 50% 60%;
    overflow: hidden;
    height: 100%;
    position: relative;
  }

  .class-icongroup {
    color: white;
  }

  .navmobile:hover {
    font-size: 15px;
    cursor: pointer;
  }

  .overlay-class {
    position: absolute !important;
    /* height: (100% - 64px) !important; */
    z-index: 10000000000;
    top: 64px !important;
    width: 100%;
    height: 93.2% !important;
    background: #ffffff;

  }

  .tab-overlay {
    height: 500px !important;
    width: 100% !important;
    height: 99%;
  }

  .bottom-sheet-mobile-background {
    background-image: url("~@/assets/sheet2.jpg") !important;
    background-size: cover !important;
    background-position: 50% 100% !important;
    overflow-y: hidden;
  }

  .about-us {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 50;
  }
</style>