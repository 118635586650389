import axios from 'axios'
import router from '@/router'
import { bus } from '@/main';
import appMixins from '@/mixins/globalMixins'

export default {
    state: {
        user: '',
        auth: {
            token: 'null',
        },
        loading: false,
        error: null,
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload
        },
        setLoading(state, payload) {
            state.loading = payload
        },
        setError(state, payload) {
            state.error = payload
        },
        clearError(state) {
            state.error = null
        },
    },
    actions: {

        setUser({ commit }, payload) {
            commit('setUser', payload)
        },

        signUserIn({ commit, getters }, payload) {
            commit('setLoading', true)
            axios
                .post(appMixins.data().globalUrl+`users/login`, payload)
                .then((response) => {                    
                    commit('setLoading', false)
                    const newUser = payload
                    commit('setUser', response.data)
                    localStorage.setItem('user', JSON.stringify(response.data))
                    router.push('/Dashboard')
                })
                .catch((error) => {
                    commit('setLoading', false);
                    commit('setError', error.response.data);
                    //alert('wrong username/password');

                    bus.$emit('triggerSnackBar');

                    if (error.response.status === 401) {
                        this.signOut();
                    }
                })
        },

        signOut({ commit }) {
            localStorage.clear()
            commit('setUser', null)
            commit('setError', null)
            router.push('/').catch(()=>{});
        },

        checkUserLocalStorage({ commit, getters }) {
            const user = JSON.parse(localStorage.getItem('user'))
            if (user) {
                commit('setUser', user)
            }
        },

        clearError({ commit }) {
            commit('clearError')
        },

        refreshToken({ state }) {
            //alert('token '+state.user.token)
            return new Promise((resolve, reject) => {

                if(!state.user || !state.user.token){ 
                    console.log('no token found'); 
                    return false; 
                }
                else {
                    axios
                    .post(appMixins.data().globalUrl+`users/renewjwt`, null, {  //renewjwt xperlu body, only header
                        headers: {
                            Authorization: 'Bearer ' + state.user.token,
                        },
                    })
                    .then((response) => {
                        // update token on user state
                        // update token on localstorage
                        console.log('token renew at '+new Date());
                        state.user.token = response.data.newjwt;  //get new jwt n put it in state
                        window._mycurrentjwt = state.user.token;  //_mycurrentjwt just for debug
                        resolve(response)
                    })
                    .catch((error) => {
                        // set user on state to null
                        // clear local storage
                        // goto login page

                        if(location.pathname.includes("PublicDisplay") == false){
                            router.push('/').catch(()=>{})  //kene login
                        }

                        

                        
                        // reject(error)
                    })
                }

            })
        },
    },
    getters: {
        user(state) {
            return state.user
        },
        token(state) {
            return state.user.token
        },
        error(state) {
            return state.error
        },
        loading(state) {
            return state.loading
        },
        userRole(state) {
            return state.user.role
        },

    },
}
