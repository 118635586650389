<template>
  <div v-if="$route.meta.showPDHeader">
    
    <v-app-bar id="pd-header" class="class-appbar" clipped-right clipped-left app elevation="1">

      <v-app-bar-nav-icon class="pa-3 class-logo ml-10">
        <v-img src="../assets/Jata_Logo.png" height="50" contain @click="goToPage('/PublicDisplay/Homepage')">
        </v-img>
      </v-app-bar-nav-icon>

      <v-toolbar-title class="class-title ml-16" v-if="$vuetify.breakpoint.mdAndUp">
        WATER QUALITY MONITORING STATION
      </v-toolbar-title>

        <v-toolbar-title 
          class="class-title-mobile ml-8"
          v-else
        >
          <h3>WQTS</h3> 
        </v-toolbar-title>

      <v-spacer></v-spacer>
      
      
    </v-app-bar>

  </div>
</template>

<script>
import { bus } from '@/main';

export default {

  data: () => ({
  }),

  mounted() {
   
  },

  methods: {
  },

  created(){
   
  }

};
</script>

<style lang="scss">
@import '~scss/main';

.class-logo {
  position: absolute;
  left: 30px;
}
.class-title {
  position: absolute;
  left: 100px;
  color: white;
  line-height: normal !important;
}

.class-title-mobile{
  position: absolute;
  left: 80px;
  color: white;
}

.class-appbar {
  // background-image: linear-gradient(to right, #343333, #5a5a5a) !important;
  // background-image: linear-gradient(to left,#02B3C3, #0081BA) !important; 
  background-image: url("~@/assets/header2.jpg") !important;
  background-size: cover !important;
  background-position: 50% 60%;
  overflow-y: hidden;
  height: 100%;
  position: relative;
}

.class-icongroup {
  color: white;
}

.navmobile:hover {
  font-size: 15px;
  cursor: pointer;
}

.bottom-sheet-mobile-background{
  // background-color:whitesmoke !important;
  background-image: url("~@/assets/sheet2.jpg") !important;
  background-size: cover !important;
  background-position: 50% 100% !important;
  overflow-y: hidden;
}


</style>


