export default {

    data() {


        return {
            //globalUrl: "https://water.sensesb.com.my/apis/",

            // globalUrl: location.hostname == "http://bakaj.serasi.tech.com.my/" ? "https://water.sensesb.com.my/apis/" 
            //                             : "https://donutgebu.shaz.my/apis/",

            globalUrl: location.hostname == "http://bakaj.serasi.tech.com.my/" ? "https://water.sensesb.com.my/apis/" 
                                        : "https://water.sensesb.com.my/apis/",

        }
    },    

    mounted(){
        
    },

    methods: {

        goToPage(url){
            this.$router.push({ path: url }).catch(()=>{});
        },
        reloadPage(){
            window.location.reload();
        },

        convertDateDisplay(date){

            if(date != undefined && date != null && date?.trim() != ''){
                
                // Change Date
                var [yyyy,mm,dd] = date.split("-");
                date = dd + "/" + mm + "/" + yyyy;

                return date;

            }
            else{

                return date;
                
            }

        },

        convertDatetimeDisplay(datetime){

            if(datetime != undefined && datetime != null && datetime?.trim() != ''){

                datetime.includes("T")? datetime = datetime.replace("T", " ") : datetime;

                let [date,time] = datetime.split(" ");
                
                // Change Date
                var [yyyy,mm,dd] = date.split("-");
                date = dd + "/" + mm + "/" + yyyy;
                
                // Change Time
                var H = +time.substr(0, 2);
                var h = H % 12 || 12;
                var ampm = (H < 12 || H === 24) ? "AM" : "PM";
                time = h + time.substr(2, 3) + time.substr(5, 3) + ' ' + ampm;

                datetime = date + " " + time

                return datetime;

            }
            else{

                return datetime;
                
            }

        }
        
    },
    
    computed: {

        // levelAdmin() {
        //     return this.$store.getters.userRole === 'admin' ? true : false
        // },

        // levelUser() {
        //     return this.$store.getters.userRole === 'user' ? true : false
        // },

    },
}